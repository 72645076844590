* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.App {
  text-align: center;
}
section {
  width: 100%;
  max-width: 1200px;
  margin: 40px auto;
  padding: 0 20px;
}
/* Top Header (Email and Language) */
.top-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  background-color: #f8f8f8;
  border-bottom: 1px solid #e0e0e0;
}

.header-left p, .header-right p {
  margin: 0;
  color: #333;
  font-size: 14px;
}

/* Nav Bar */
.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  background-color: white;
}

.logo {
  flex-shrink: 0;
}

/* Center the nav links */
.nav-links {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  gap: 20px;
}

.nav-links a {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.nav-links a:hover {
  color: #007BFF;
}

.social-icons {
  display: flex;
  gap: 15px;
}

.social-icons a {
  color: black;
  font-size: 20px;
}

/* Image Slider Styles */
.slider-container {
  position: relative;
  width: 100%;
  margin: auto;
  display: flex;
  overflow: hidden;
  height: 600px; /* Fixed height for the slider */
}

.slider-image {
  width: 100%;
  height: auto;
}

.arrow {
  cursor: pointer;
  font-size: 2em;
  color: white;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}

.arrow:hover {
  color: lightgray;
}

.logo {
  display: flex;
  height: 80px;
}

.logo-image {
  width: 100px;
  height: auto;
}

.bold-text {
  font-weight: bold;
}

/* Responsive Design */

@media screen and (max-width: 1200px) {
  .nav-bar {
    padding: 15px 20px;
  }

  .nav-links {
    gap: 15px;
  }

  .slider-container {
    height: 500px; /* Adjust slider height for medium screens */
  }

  .logo {
    height: 70px;
  }

  .logo-image {
    width: 80px;
  }
}

@media screen and (max-width: 768px) {
  .top-header {
    flex-direction: column;
    text-align: center;
    padding: 10px 15px;
  }

  .nav-bar {
    flex-direction: column;
    padding: 10px 15px;
  }

  .nav-links {
    flex-direction: column;
    gap: 10px;
  }

  .slider-container {
    height: 400px; /* Adjust slider height for smaller screens */
  }

  .logo {
    height: 60px;
  }

  .logo-image {
    width: 60px;
  }

  .arrow {
    font-size: 1.5em;
  }
}

@media screen and (max-width: 480px) {
  .nav-bar {
    padding: 5px 10px;
  }

  .nav-links {
    gap: 5px;
  }

  .slider-container {
    height: 300px; /* Adjust slider height for mobile screens */
  }

  .logo {
    height: 50px;
  }

  .logo-image {
    width: 50px;
  }

  .arrow {
    font-size: 1.2em;
  }

  .social-icons a {
    font-size: 18px;
  }
}

