.certificates-section {
  padding: 50px 0;
}

.certificates-container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: left;
}

.certificates-container h1 {
  font-weight: bold;
  margin-bottom: 20px;
}

.certificates-container p {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 40px;
}

/* Certificates Grid Layout */
.certificates-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

/* Certificate Item Styles */
.certificate-item {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.certificate-item img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 15px;
}

.certificate-item h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

.certificate-item p {
  font-size: 1rem;
  color: #666;
}

.certificate-description {
  margin-top: 10px;
  line-height: 1.6;
}

/* Responsive Design */

/* Medium screens (tablets) */
@media screen and (max-width: 992px) {
  .certificates-container h1 {
    font-size: 1.8rem;
  }

  .certificates-container p {
    font-size: 1rem;
  }

  .certificate-item {
    padding: 15px;
  }

  .certificate-item h3 {
    font-size: 1.3rem;
  }

  .certificate-item p {
    font-size: 0.95rem;
  }
}

/* Small screens (small tablets and large phones) */
@media screen and (max-width: 768px) {
  .certificates-container {
    padding: 0 20px;
  }

  .certificates-container h1 {
    font-size: 1.6rem;
  }

  .certificates-container p {
    font-size: 0.9rem;
  }

  .certificate-item h3 {
    font-size: 1.2rem;
  }

  .certificate-item p {
    font-size: 0.9rem;
  }
}

/* Extra small screens (mobile devices) */
@media screen and (max-width: 480px) {
  .certificates-section {
    padding: 30px 0;
  }

  .certificates-container {
    padding: 0 15px;
  }

  .certificates-container h1 {
    font-size: 1.4rem;
    margin-bottom: 15px;
  }

  .certificates-container p {
    font-size: 0.85rem;
    margin-bottom: 30px;
  }

  .certificates-grid {
    grid-template-columns: 1fr;
  }

  .certificate-item {
    padding: 10px;
  }

  .certificate-item h3 {
    font-size: 1.1rem;
  }

  .certificate-item p {
    font-size: 0.85rem;
  }
}

