.services-section {
  padding: 50px;
  text-align: left; 
}

.services-container {
  max-width: 1200px;
  margin: 0 auto;
}

.services-container h2 {
  font-weight: bold;
  margin-bottom: 20px;
}

.services-container p {
  font-size: 1rem;
  margin-bottom: 40px;
  color: #666;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  text-align: left; 
}

.service-item {
  text-align: left; 
}

.service-item img {
  width: 100%;
  height: 200px; /* Fixed height */
  object-fit: cover; /* Crop and adjust the image to fit the container */
  border-radius: 8px;
  margin-bottom: 10px;
}

.service-item h3 {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

/* Responsive Design */

/* Medium screens (tablets and smaller laptops) */
@media screen and (max-width: 992px) {
  .services-section {
    padding: 40px;
  }

  .services-container h2 {
    font-size: 1.5rem;
  }

  .services-container p {
    font-size: 0.9rem;
  }

  .service-item img {
    height: 180px; /* Adjust the height for smaller screens */
  }

  .service-item h3 {
    font-size: 1.1rem;
  }
}

/* Small screens (tablets, small tablets) */
@media screen and (max-width: 768px) {
  .services-section {
    padding: 30px;
  }

  .services-container p {
    font-size: 0.85rem;
    margin-bottom: 30px;
  }

  .service-item img {
    height: 160px; /* Adjust the height for smaller screens */
  }

  .service-item h3 {
    font-size: 1rem;
  }
}

/* Extra small screens (mobile devices) */
@media screen and (max-width: 480px) {
  .services-section {
    padding: 20px;
  }

  .services-container h2 {
    font-size: 1.2rem;
    margin-bottom: 15px;
  }

  .services-container p {
    font-size: 0.8rem;
    margin-bottom: 20px;
  }

  .service-item img {
    height: 140px; /* Adjust the height for mobile devices */
  }

  .service-item h3 {
    font-size: 0.9rem;
  }
}

