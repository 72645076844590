/* Main section styling */
.hakkimizda-section {
  padding: 50px 0;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

/* Left Column (Text) */
.left-column {
  width: 50%;
  text-align: left;
  padding-right: 20px;
}

.left-column h1 {
  font-weight: bold;
  color: #000;
  margin-bottom: 15px;
}

.left-column h2 {
  color: #8cc63f;
  font-weight: bold;
  margin-bottom: 20px;
}

.left-column p {
  font-size: 1rem;
  margin: 15px 0;
  color: #333;
  line-height: 1.6;
}

/* Faded Text */
.faded-text {
  opacity: 0.4; 
  transition: opacity 0.3s ease-in-out; 
}

.faded-text.show-more {
  opacity: 1; 
}

/* Read More Link */
.read-more {
  display: inline-block;
  margin-top: 20px;
  color: black;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  font-size: 1rem;
}

.read-more:hover {
  color: #007BFF;
}

/* Right Column (Image) */
.right-column {
  width: 50%;
  padding-left: 20px;
}

.right-column img {
  width: 100%;
  border-radius: 8px;
}

/* Subsection */
.sub-section {
  background-color: #8cc63f;
  padding: 40px;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 40px auto 0 auto;
  border-radius: 8px;
}

.sub-section-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
}

.left-image {
  width: 30%;
}

.right-text {
  width: 65%;
  text-align: left;
}

.sub-section-image {
  width: 100%;
  height: auto;
}

.sub-section h2 {
  font-size: 2rem;
  color: white;
  font-weight: bold;
}

.sub-section p {
  color: white;
  margin: 15px 0;
  line-height: 1.6;
}

/* Detail Button */
.detail-button {
  display: inline-block;
  margin-top: 20px;
  background-color: white;
  color: #8cc63f;
  padding: 10px 20px;
  border-radius: 50px; 
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  border: 2px solid #8cc63f; 
}

.detail-button:hover {
  background-color: #f2f2f2; 
}

/* Responsive Design */

/* Medium screens (tablets) */
@media screen and (max-width: 992px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .left-column, .right-column {
    width: 100%;
    padding: 0;
  }

  .left-column {
    padding-bottom: 20px;
  }

  .right-column {
    padding-top: 20px;
  }

  .sub-section-content {
    flex-direction: column;
    text-align: center;
  }

  .left-image, .right-text {
    width: 100%;
  }

  .sub-section h2 {
    font-size: 1.8rem;
  }
}

/* Small screens (mobile devices) */
@media screen and (max-width: 768px) {
  .left-column h1 {
    font-size: 1.6rem;
  }

  .left-column h2 {
    font-size: 1.4rem;
  }

  .left-column p {
    font-size: 0.9rem;
  }

  .sub-section {
    padding: 30px;
  }

  .sub-section h2 {
    font-size: 1.6rem;
  }

  .sub-section p {
    font-size: 0.9rem;
  }

  .detail-button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}

/* Extra small screens (mobile devices) */
@media screen and (max-width: 480px) {
  .container {
    padding: 0 15px;
  }

  .left-column h1 {
    font-size: 1.4rem;
  }

  .left-column h2 {
    font-size: 1.2rem;
  }

  .left-column p {
    font-size: 0.85rem;
  }

  .sub-section {
    padding: 20px;
  }

  .sub-section h2 {
    font-size: 1.4rem;
  }

  .sub-section p {
    font-size: 0.85rem;
  }

  .detail-button {
    font-size: 0.85rem;
    padding: 6px 12px;
  }
}

